import { css } from '@emotion/react';
import { Button, LinearProgress, linearProgressClasses } from '@mui/material';
import React, { memo, useEffect, useMemo, useState } from 'react';
import dayAPI from '~/utils/dayAPI';
import { globalBlueGrey, globalOrange, WHITE, globalYellow, FUTURESAI_THEME, } from '~/modules/AppLayout/Colors';
import { flex } from '~/modules/AppLayout/FlexGridCss';
import { useFuturesaiTutorialState } from '~/modules/tutorial/useFuturesaiTutorialState';
import { useMeStore } from '~/modules/SDK/me/useMeStore';
import { useRouter } from 'next/router';
import ExtendTrialSubscription from './ExtendTrialSubscription';
import { thirdActionButtonCss } from '~/modules/tutorial/tutorialButtonCss';
import { fontWeight600 } from '~/css/font';
import { apirc } from '~/configs/apirc';
import { FreeTrialAction } from '~/modules/tutorial/FreeTrialAction';
const THREE_DAY_UNIX = 60 * 60 * 24 * 3;
/**
 * 如何不顯示此區塊？
 *
 * Case 1: 根本還沒登入
 *
 * Case 2: 舊版 API 沒有 status 這個欄位 (undefined)
 *
 * Case 3: 還很多天，不用管～
 */
const ExpireReminder = memo(function ExpireReminder(props) {
    const { state } = useFuturesaiTutorialState.useContainer();
    const meFirebaseState = useMeStore(s => s.meFirebaseState);
    const router = useRouter();
    // 沒有 web 商品（/signup 是新增 default 商品） || web.status === null
    const haveStatus = state.tutorialProduct?.status;
    /**
     * `counter`: 初始狀態，根據`state.tutorialProduct?.expiredAt`時間決定起始狀態
     *
     * `countdown`: 渲染倒數部分
     */
    // 試用狀態 && 還沒過期 才需要counter
    const counter = useMemo(() => {
        return haveStatus && dayAPI(state.tutorialProduct?.expiredAt).diff(dayAPI()) / 1000 > 0
            ? dayAPI(state.tutorialProduct?.expiredAt).diff(dayAPI()) / 1000
            : undefined;
    }, [haveStatus, state.tutorialProduct?.expiredAt]);
    const [countdown, setCountdown] = useState();
    // 有必要 counter，且目前 countdown 尚未有值
    if (counter !== undefined && countdown === undefined) {
        setCountdown(counter);
    }
    const productStatus = state.tutorialProduct?.status;
    const countdownBoxCss = css `
    border: 2px solid ${productStatus === 'trial' ? globalBlueGrey.bg50 : globalOrange.o200};
    border-radius: 8px;
    ${flex.v.default};
    margin: 8px;
    padding: 8px;
    p {
      margin: 0;
    }
  `;
    const countdownHeaderCss = css `
    font-size: 0.7rem;
    ${fontWeight600};
    color: ${productStatus === 'trial' ? FUTURESAI_THEME : globalOrange.o800};
  `;
    const countdownTextCss = css `
    color: ${productStatus === 'trial' ? FUTURESAI_THEME : globalOrange.o800};
    font-size: 0.8rem;
  `;
    const progressCss = css `
    background: ${productStatus === 'trial' ? '' : `${globalOrange.o200}!important`};
    .${linearProgressClasses.bar} {
      background: ${productStatus === 'trial' ? '' : `${globalOrange.o200}!important`};
    }
  `;
    useEffect(() => {
        const timer = setInterval(() => {
            if (countdown)
                setCountdown(countdown - 1);
            /** 倒數兩秒 reload */
            if (typeof countdown === 'number' && countdown < 2) {
                apirc.me.api.getMe.fetch();
                router.reload();
            }
        }, 1000);
        return () => clearTimeout(timer);
    }, [counter, setCountdown, countdown, router]);
    /** 未登入 大於三天不需要倒數 */
    if (!meFirebaseState || (countdown && countdown > THREE_DAY_UNIX))
        return null;
    const yellowBtnProps = {
        css: css `
      background: ${globalYellow.y800}!important;
      color: ${WHITE};
    `,
        size: 'small',
        variant: 'contained',
        fullWidth: true,
        target: '_blank',
        href: 'https://m.me/futures.ai.tw/',
    };
    return (<div>
      {/* 有狀態(trial || premium)  */}
      {haveStatus ? (<div css={flex.v.mainCenter}>
          <div css={countdownBoxCss}>
            <p css={countdownHeaderCss}>
              {state.tutorialProduct?.status === 'trial' ? ' 試用天數：' : ' 權限即將到期：'}
            </p>
            <p css={countdownTextCss}>
              {/* 還可以倒數？ 倒數：過期了 */}
              {countdown ? (<>
                  {Math.floor(countdown / 60 / 60 / 24)}天{Math.floor(countdown / 60 / 60) % 24}時
                  {Math.floor((countdown / 60) % 60)}分{Math.floor(countdown % 60)}秒
                </>) : (<>0天0時0分0秒</>)}
            </p>
            <LinearProgress css={progressCss} variant='determinate' value={((countdown ? countdown : 1) / THREE_DAY_UNIX) * 100}/>
          </div>
          <div css={css `
              margin: 0 8px;
            `}>
            {/* trial 過期 -> 問卷＋聯繫小編按鈕 */}
            {!countdown && state.tutorialProduct?.status === 'trial' ? (<div css={css `
                  ${flex.v.allCenter};
                  & > * {
                    margin: 4px 0 !important;
                  }
                `}>
                <ExtendTrialSubscription buttonStyle={thirdActionButtonCss}/>
                <Button {...yellowBtnProps}>升等付費會員</Button>
              </div>) : null}
            {/* premium && (三天內到期 || 已經到期) -> 點我聯繫小編預約 */}
            {state.tutorialProduct?.status === 'premium' &&
                ((countdown && countdown < THREE_DAY_UNIX) || !countdown) && (<Button {...yellowBtnProps}>點我聯繫小編續約</Button>)}
          </div>
        </div>) : (<div css={flex.h.allCenter}>
          {/**
             * 兩種狀態：
             *
             * - 舊版：預設有個 `web` 商品，status 為 null
             * - 新版：透過註冊 /signup 會新增一個 `default` 商品，不會有 `web`商品
             */}
          <FreeTrialAction text='點我開始三天試用'/>
        </div>)}
    </div>);
});
export default ExpireReminder;
