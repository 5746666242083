import { css } from '@emotion/react';
import { Button, Dialog, DialogContent } from '@mui/material';
import React, { memo, useState } from 'react';
import { useFuturesaiTutorialState } from '~/modules/tutorial/useFuturesaiTutorialState';
import { flex } from '~/modules/AppLayout/FlexGridCss';
import { thirdActionButtonCss } from '~/modules/tutorial/tutorialButtonCss';
/**
 * 什麼場合會出現這個？
 *
 * 當status === trial也就是「試用會員」身份，到期了還可以送延長試用機會的按鈕
 * */
export const ExtendTrialSubscription = memo(function ExtendTrialSubscription(props) {
    const [open, setOpen] = useState(false);
    const { state } = useFuturesaiTutorialState.useContainer();
    const handleClickOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    if (state.tutorialProduct?.status !== 'trial')
        return null;
    return (<>
      <Button size='small' css={props.buttonStyle} variant='contained' onClick={handleClickOpen}>
        填寫問卷延長試用
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogContent css={css `
            position: relative;
            ${flex.v.allCenter};
            width: 600px;
            height: 480px;
            background: url('/futuresai/trial/root.png');
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
          `}>
          <div css={css `
              position: absolute;
              bottom: 24px;
            `}>
            <Button css={thirdActionButtonCss} variant='contained' href='https://www.surveycake.com/s/ZZwe7' target='_blank'>
              點我填表單
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    </>);
});
export default ExtendTrialSubscription;
