import { Button, Dialog, DialogActions, DialogTitle } from '@mui/material';
import React, { memo, useState } from 'react';
import { useFuturesaiTutorialState } from '~/modules/tutorial/useFuturesaiTutorialState';
import { useMeStore } from '~/modules/SDK/me/useMeStore';
import { authDialogState } from '~/modules/auth/containers/authDialogStore';
import { apirc } from '~/configs/apirc';
export const FreeTrialAction = memo(function FreeTrialAction(props) {
    const [open, setOpen] = useState(false);
    const { state, acts } = useFuturesaiTutorialState.useContainer();
    const meInfoState = useMeStore(s => s.meInfoState);
    /**
     * 有登入？
     *
     * - If 沒填過表單？ 顯示表單 Dialog
     * - Else 繼續走
     *
     * 沒登入
     *
     * - 確保TutorialDialog關閉
     * - 打開登入Dialog
     */
    const handleClickOpen = () => {
        if (state.hasLogin) {
            if (!meInfoState) {
                acts.setFillFormOpen(true);
                return;
            }
            setOpen(true);
        }
        else {
            acts.setManualTutorDialogOpen(false);
            authDialogState.open = true;
        }
    };
    const handleClose = () => setOpen(false);
    // 若為有狀態的訂閱，premium, trial，則不顯示
    if (state.tutorialProduct?.status)
        return null;
    return (<>
      <Button color='warning' variant='contained' onClick={handleClickOpen}>
        {props.text}
      </Button>
      <Dialog open={open} onClose={handleClose} aria-labelledby='alert-dialog-title' aria-describedby='alert-dialog-description'>
        <DialogTitle>確定要開始試用嗎？</DialogTitle>

        <DialogActions>
          <Button onClick={handleClose}>再想想</Button>
          <Button onClick={() => {
            handleClose();
            apirc.me.api.postFreeTrial.fetch();
        }} variant='contained' color='primary'>
            是的
          </Button>
        </DialogActions>
      </Dialog>
    </>);
});
