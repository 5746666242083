import { css } from '@emotion/react';
import { WHITE } from '~/modules/AppLayout/Colors';
import { fontWeight600 } from '~/css/font';
//#03989e
export const thirdActionButtonCss = css `
  min-width: 160px;
  background: #03989e;
  color: ${WHITE};
  ${fontWeight600};
  font-size: 1rem;
`;
