import { css } from '@emotion/react';
import React, { memo } from 'react';
import { FaLink } from 'react-icons/fa';
import { flex } from '~/modules/AppLayout/FlexGridCss';
import { fontWeight600 } from '~/css/font';
import { AppLink2 } from '~/components/AppLink2';
export const AppSidebarItem = memo(function AppSidebarItem(props) {
    return (<AppLink2 href={props.href || ''} css={css `
        ${flex.h.crossCenter};
        width: 100%;
        height: 48px;
        padding: 8px 16px;
        text-decoration: none;

        &:hover {
          ${fontWeight600};
        }

        ${props.customizedRootCSS}
      `}>
      <div css={css `
          ${flex.inline.allCenter};
          line-height: 24px;
          & > *:nth-of-type(1) {
            margin-right: 16px;
          }
        `}>
        {props.hideIcon || props.startIcon || <FaLink />}
        {props.children}
      </div>
    </AppLink2>);
});
