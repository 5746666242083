import { IconButton } from '@mui/material';
import React, { memo } from 'react';
import { useFuturesaiTutorialState } from '~/modules/tutorial/useFuturesaiTutorialState';
import { flex } from '~/modules/AppLayout/FlexGridCss';
import useMedia from '~/hooks/useMedia';
import { css } from '@emotion/react';
import { RiMessengerFill } from 'react-icons/ri';
import { globalBlue } from '~/modules/AppLayout/Colors';
import { AppLink } from '~/components/AppLink';
const GuideAssistant = memo(function GuideAssistant(props) {
    const { acts } = useFuturesaiTutorialState.useContainer();
    const { isPhone, isPad } = useMedia();
    return (<div css={flex.h.allCenter}>
      <AppLink target='_blank' href='https://m.me/futures.ai.tw/'>
        <IconButton>
          <RiMessengerFill css={css `
              font-size: ${isPhone ? 2 : 2.2}rem;
              color: ${globalBlue.b400};
              &:hover {
                color: ${globalBlue.bA400};
              }
            `}/>
        </IconButton>
      </AppLink>
      {isPhone || isPad ? null : (<IconButton color='primary' onClick={() => acts.setManualTutorDialogOpen(true)}>
          <img css={css `
              width: 32px;
            `} src='/futuresai/tutorial/question.png'/>
        </IconButton>)}
    </div>);
});
export default GuideAssistant;
