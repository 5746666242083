import { css } from '@emotion/react';
import { memo } from 'react';
import { flex, jc } from '~/modules/AppLayout/FlexGridCss';
import { agentConfigs2 } from '~/configs/agentConfigs';
import { fontWeight600 } from '~/css/font';
import { baseNoLinkCss } from '~/css/noLinkCss';
import { AppLink2 } from '~/components/AppLink2';
const agentConfig = agentConfigs2['futuresai@web'];
export const FuturesaiSidebarBrand = memo(function FuturesaiSidebarBrand(props) {
    return (<div>
      <AppLink2 href={`/${agentConfig.name || ''}`} css={css `
          ${baseNoLinkCss};
          ${flex.h.crossCenter};
          ${jc.flexStart};
          text-decoration: none;
          height: 56px;
          margin-left: 16px;
        `}>
        <img width={32} height={32} src={agentConfig.favicon} alt='ICON'/>
        <p css={css `
            font-size: 1.5rem;
            ${fontWeight600};
            margin: 0;
            margin-left: 8px;
            color: black;
          `}>
          {props.children ? props.children : agentConfig.title}
        </p>
      </AppLink2>
    </div>);
});
