import { css } from '@emotion/react';
import React, { memo } from 'react';
import { AppSidebarItem } from '~/modules/AppLayout/AppSidebarItem';
import { globalBlue, globalBlueGreen, globalBlueGrey, globalOrange, } from '~/modules/AppLayout/Colors';
import ExpireReminder from '~/modules/tutorial/ExpireReminder';
import GuideAssistant from '~/modules/tutorial/GuideAssistant';
import { fontWeight600 } from '~/css/font';
import { FuturesaiSidebarBrand } from '~/modules/futuresai-static/FuturesaiSidebarBrand';
import { LoginSidebarItem } from '~/modules/AppLayout/LoginSidebaritem';
import { createDesktopCss } from '~/css/createDesktopCss';
const futuresaiLinks = [
    { title: '即時指標', url: '/futuresai/quote' },
    {
        title: '選擇權',
        url: '/futuresai/opbs',
        children: [
            { title: '選擇權分析', url: '/futuresai/opbs' },
            { title: '選擇權報價', url: '/futuresai/option-price' },
            { title: '選擇權OI觀察', url: '/futuresai/options-oi' },
        ],
    },
    {
        title: '股市儀表',
        url: '/futuresai/screener',
        children: [
            { title: '即時排行', url: '/futuresai/ranking-realtime' },
            { title: '權值排行', url: '/futuresai/ranking' },
            { title: '股期排行', url: '/futuresai/stock-futures' },
            { title: '大盤籌碼', url: '/futuresai/daily-chips' },
            { title: '個股篩選', url: '/futuresai/screener' },
            //{ title: '訊號牆', url: '/futuresai/signal-wall' },
            { title: '盤中大單動向', url: '/futuresai/large-trade' },
            { title: '基本面研究', url: '/futuresai/stock-analysis' },
        ],
    },
    {
        title: '市場監控',
        url: '/futuresai/monitors/stock',
        children: [
            { title: '權值股監控', url: '/futuresai/monitors/stock' },
            { title: '海期監控', url: '/futuresai/monitors/os-futures' },
            { title: '台股漲跌家數', url: '/futuresai/stock-price-change-distribution' },
            { title: '加密貨幣', url: '/futuresai/crypto' },
            { title: '美股', url: '/futuresai/us-stock' },
        ],
    },
    {
        title: '交易筆記',
        url: '/futuresai/miscellaneous/os-futures-plans',
        children: [
            { title: '海期規劃', url: '/futuresai/miscellaneous/os-futures-plans' },
            { title: '雜談', url: '/futuresai/miscellaneous/mantra' },
            { title: '系統教學手冊', url: '/futuresai/miscellaneous/user-manual' },
        ],
    },
    {
        title: '統計分析資訊',
        url: '/futuresai/daily-statistics',
        children: [
            { title: '當日統計資訊', url: '/futuresai/daily-statistics' },
            { title: '漲跌振幅分佈', url: '/futuresai/kbar-statistics' },
            { title: '走勢軌跡分析', url: '/futuresai/historical-chart' },
            { title: '後勢統計圓餅圖', url: '/futuresai/vix-statistics' },
        ],
    },
    {
        title: '程式策略',
        url: '/futuresai/strategy-overview',
        children: [
            { title: '策略總覽', url: '/futuresai/strategy-overview' },
            { title: '動態回測', url: '/futuresai/backtest' },
        ],
    },
];
const rootLinkCss = css `
  &:hover {
    background: ${globalBlueGrey.bg50};
    border-left: ${globalBlue.b800} 3px solid;
  }
  height: 40px;
  ${fontWeight600};
  font-size: 18px;
  color: ${globalBlueGrey.bg800};
`;
const FuturesaiSideBarItems = memo(function FuturesaiSideBarItems(props) {
    return (<div css={css `
          max-width: 200px;
          background: white;
          ${createDesktopCss(css `
            width: 200px;
            position: fixed;
            top: 0;
            overflow: auto;
            height: 100vh;
            border-right: black 1px solid;
            z-index: 8;
          `)}
        `}>
        <div css={css `
            position: relative;
          `}>
          <FuturesaiSidebarBrand />
          {/* <FuturesaiSidebarDrawerToggle /> */}
        </div>
        <LoginSidebarItem />
        {/* 在入口網站不需要 */}
        {props.hideTrialButton || <ExpireReminder />}
        {futuresaiLinks.map((root, index) => {
            if (root.hide)
                return;
            return (<div key={index}>
              <AppSidebarItem href={root.url} customizedRootCSS={rootLinkCss} hideIcon={true}>
                {root.title}
              </AppSidebarItem>
              {root.children &&
                    root.children.map((subUrl, subIndex) => {
                        if (subUrl.hide)
                            return;
                        return (<AppSidebarItem customizedRootCSS={css `
                        text-decoration: none;
                        padding: 0 0 0 32px;
                        height: 24px;
                        font-size: 16px;
                        letter-spacing: -1px;
                        border-radius: 4px;
                        color: ${globalBlueGreen.bg700};
                        &,
                        &:active,
                        &:visited {
                          color: black;
                        }
                        &:hover {
                          background: ${globalOrange.o50}55;
                        }
                      `} key={subIndex} hideIcon={true} href={subUrl.url}>
                      {subUrl.title}
                    </AppSidebarItem>);
                    })}
            </div>);
        })}
        <GuideAssistant />
      </div>);
});
export default FuturesaiSideBarItems;
