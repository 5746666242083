import React, { memo } from 'react';
import { css } from '@emotion/react';
import { fontWeight600 } from '~/css/font';
import { UserAvatarAsDialogButton } from '~/modules/auth/components/UserAvatarAsDialogButton';
import { useMeStore } from '~/modules/SDK/me/useMeStore';
import { flex } from '~/modules/AppLayout/FlexGridCss';
/** 僅出現在華南、期天 */
export const LoginSidebarItem = memo(function LoginSidebarItem(props) {
    const meFirebaseState = useMeStore(state => state.meFirebaseState);
    const meUserState = useMeStore(state => state.meUserState);
    const productName = useMeStore(state => state.product);
    const agentName = useMeStore(state => state.agentName);
    const productStatus = meUserState?.subscriptions.find(state => state.agentName === agentName && state.productName === productName)?.status;
    const readableProductStatus = productStatus === 'premium' ? '付費會員'
        : productStatus === 'trial' ? '試用會員'
            : productStatus === 'activation_code' ? '試用會員'
                : '未開通會員';
    return (<div css={css `
        ${flex.h.crossCenter};
        height: 64px;
        padding: 8px;
        gap: 18px;
        p {
          margin: 0;
        }
      `}>
      {meFirebaseState ? null : (<img css={css `
            width: 32px;
          `} src={`/futuresai/auth/login-icon.png`}/>)}
      <UserAvatarAsDialogButton loginAsText='會員登入' rootCSS={meFirebaseState
            ? css ``
            : css `
                padding: 2px 16px;
                margin-left: -8px;
                border-radius: 8px;
                background: #5f8c99;
                color: white;
                width: 100%;
              `}/>
      <div css={css `
          ${flex.v.allCenter};
          margin: 0 0 0 -8px !important;
          color: black;
        `}>
        {meUserState && (<>
            <div css={flex.h.crossCenter}>
              {productStatus && (<img css={css `
                    width: 16px;
                    margin-right: 8px;
                  `} src={`/futuresai/auth/badge-${productStatus === 'premium' ? 'premium' : 'trial'}.png`}/>)}
              <p css={fontWeight600}>{readableProductStatus}</p>
            </div>
            <p css={css `
                font-size: 0.6rem;
              `}>
              會員代碼: {meUserState?.code}
            </p>
          </>)}
      </div>
    </div>);
});
